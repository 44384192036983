.lineContainer {
    border: 1px solid transparent;
}

.lineContainer:active,
.lineContainer:hover {
    border: 1px solid var(--reka-colors-border-main);
}

.lineContainer.active {
    border: none;
    background: rgba(129, 81, 188, 0.25);
}

.lineContainer.visible .lineMenu,
.lineContainer.active .lineMenu,
.lineContainer:focus-within .lineMenu,
.lineContainer:hover .lineMenu {
    visibility: visible;
}

.lineMenu {
    visibility: hidden;
}

.customScroll {
    scrollbar-color: var(--reka-colors-border-alt) transparent;
    scrollbar-width: thin;
    padding-right: 12px;
}
