.chatMedia video,
.chatMedia img {
    max-height: max(400px, 30vh);
}

.messageMedia video,
.messageMedia img {
    max-height: 320px;
    border-radius: 8px;
}

.messages {
    scrollbar-width: none;
}

.messageContainer:hover .editable {
    visibility: initial;
}

.messageContainer .editable {
    visibility: hidden;
}

@keyframes pulse {
    0% {
        box-shadow: 0 0 5px rgba(255, 255, 255, 0.2);
    }
    50% {
        box-shadow: 0 0 15px rgba(255, 255, 255, 0.6);
    }
    100% {
        box-shadow: 0 0 5px rgba(255, 255, 255, 0.2);
    }
}

.pulse {
    animation: pulse 1.5s infinite;
    border-radius: 50%;
}

@keyframes blink {
    0% {
        opacity: 0.2;
    }
    50% {
        opacity: 1;
    }
    100% {
        opacity: 0.2;
    }
}

.blink {
    animation: blink 1.5s infinite;
}

.pulse {
    animation: pulse 1.5s infinite;
    border-radius: 50%;
}
