.markdown > * {
    overflow-wrap: anywhere;
}

.markdown {
    font-weight: 500;
}

.markdown p {
    margin-top: 0;
    margin-bottom: 1rem;
    line-height: 1.5rem;
}

.markdown em {
    font-style: italic;
    font-weight: 400;
}

.markdown strong {
    font-weight: 700;
}

.markdown ol,
.markdown ul {
    /* list-style-position: inside; */
    padding: revert;
    margin-bottom: 1em;
}

.markdown pre {
    margin-bottom: 1em;
}

.markdown li p,
.markdown ul p {
    display: inline;
}

.markdown h1,
.markdown h2,
.markdown h3,
.markdown h4,
.markdown h5,
.markdown h6 {
    margin-bottom: 0.5em;
    color: var(--reka-colors-text-primary);
    font-weight: 400;
}

.markdown h1,
.markdown h2 {
    margin-bottom: 1rem;
    margin-top: 1.5rem;
    font-weight: 600;
    line-height: 1.25;
    border-bottom: 1px solid var(--reka-colors-header-underline);
    padding-bottom: 0.3em;
}

.markdown h1 {
    font-size: 2rem;
}
.markdown h2 {
    font-size: 1.5rem;
}

.markdown h3 {
    font-size: 1.25rem;
}

.markdown h4 {
    font-size: 1rem;
}

.markdown h5 {
    font-size: 0.875rem;
}
.markdown h6 {
    font-size: 0.85rem;
    color: var(--reka-colors-text-secondary);
}

.markdown pre p {
    line-height: revert;
    margin-bottom: 0;
}

.markdown ul ul,
.markdown ol ul,
.markdown ul ol,
.markdown ol ol {
    margin-top: 0;
    margin-bottom: 0;
}

.markdown li {
    margin-bottom: 0.15em;
}

.markdown .inlineCode {
    display: inline;
    font-size: 0.9em;
    font-weight: bold;
    color: #cc80e8;
}

.markdown .paragraph {
    white-space: pre-wrap;
}

.markdown .anchor {
    text-decoration: underline;
    color: #cc80e8;
}

.markdown table,
.markdown tr,
.markdown th,
.markdown td {
    border-width: 1px;
    text-align: left;
    border-color: var(--reka-colors-border-main);
}

.markdown thead {
    background: var(--reka-colors-background-secondary);
    font-weight: 500;
}

.markdown tbody {
    background: var(--reka-colors-background-main);
}
.markdown th,
.markdown td {
    padding: 4px 12px;
}
